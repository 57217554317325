.right-icon {
  display: inline-block;
  position: absolute;
  z-index: 1;
  left: 16.75px;
  bottom: 19px;
  cursor: pointer;
}

.center-right{
  position: absolute;
  z-index: 99;
  right: 16px;
  bottom: 21.5px;
}

.sticky-pin {
  position: fixed !important;
  top: 0 !important;
  width: 100%;
}

.top-right {
  top: 16px;
  right: 16px;
}

.top-left {
  top: 16px;
  left: 16px;
}

.top-center {
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.center-center {
  right: 0;
  transform: translate(-50%, -50%);
}

.bottom-left {
  bottom: 16px;
  left: 16px;
}

.bottom-center {
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-right {
  bottom: 16px;
  right: 16px;
}
