@import "../variables";

.dropdown-toggle::after, .dropdown-toggle::before {
  display: none !important;
}

.dropdown-divider {
  @extend .border-grey-100;
}

.dropdown-menu.filter {
  width: 27rem;
  padding: 2rem 1.5rem;
  margin: 10px 0;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  background: $white;
  z-index: 2;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
}

.tableMoreDropdown {
  position: absolute;
  @extend .center-center;
 

  .dropdown-menu {
    position: absolute;
    background: $white;
    top: 40px !important;
    border: 1px solid $gray-50;
    border-radius: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 100px !important;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

    .dropdown-item {
      color: inherit;
      padding-top: 0.325rem;
      padding-bottom: 0.325rem;

      &:active, &:focus{
        color: white !important;
      }
    }
  }

  .btn {
    padding: 0.25rem;
    border-radius: 0.375rem;
    border: 1px solid $gray-300;
  

    &:hover {
      background: $gray-50;
    }

    &:focus, &.focus {
      border: 1px solid $secondary-300 !important;
    }
  }
}

@media (max-width: 468px) {
  .dropdown-menu.filter {
    width: 26rem;
  }
}

@media (max-width: 448px) {
  .dropdown-menu.filter {
    width: 25rem;
  }
}

@media (max-width: 429px) {
  .dropdown-menu.filter {
    width: 23rem;
    margin: 5px 0 auto;
  }
}

@media (max-width: 398px) {
  .dropdown-menu.filter {
    width: 20rem;
  }
}

@media (max-width: 330px) {
  .dropdown-menu.filter {
    width: 18rem;
  }
}

@media (max-width: 310px) {
  .dropdown-menu.filter {
    width: 16.5rem;
  }
}
@media (max-width: 280px) {
  .dropdown-menu.filter {
    width: 14.5rem;
  }
}
