@import "../variables";

.bg-gray-50 {
  background-color: $gray-50;
}

.bg-gray-75 {
  background-color: $gray-75 !important;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-primary-50 {
  background-color: $primary-50;
}

.breadcrumb {
  background-color: inherit;

  li.breadcrumb-item {
    a {
      color: $gray-400;

      &:hover {
        color: $gray-500;
      }
    }
  }
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: $primary-400;
}

.spinner,
.spinner-lg,
.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
  background-color: $primary-200;
}
