

/***** MARGIN *****/

.mt-0{
  margin-top: 0 !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-1-75 {
  margin-top: 1.75rem !important;
}

.mt-0-65 {
  margin-top: 0.65rem !important;
}

/***** PADDING *****/
.ps-35{
  padding-left: 35px !important;
}

.p-custom{
  padding: 12px 16px 12px 48px;
}

/***** HEIGHT *****/ 
.min-vh-10 {
  min-height: 10vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-65 {
  min-height: 65vh !important;
}

.min-vh-70 {
  min-height: 70vh !important;
}

.min-vh-79 {
  min-height: 79vh !important;
}

.min-vh-80 {
  min-height: 80vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.h-fit-content{
  height: fit-content !important;
}

.ht-45-f {
  height: 45px !important;
}

.ht-500-f{
  height: 500px !important
}

/***** WIDTH *****/

.min-w-150{
  min-width: 150px;
}

.max-w-100 {
  max-width: 100%;
}

.w-300{
  max-width: 300px;
}
.w-550 {
  max-width: 550px;
}

@media (max-width: 1400px) {
  .p-custom{
      padding: 12px 16px;
  }
}

@media (max-width: 767px){
  .ht-500-f {
      height: auto !important
  }
}