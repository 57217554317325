@import "../variables";

.btn {
  height: 3rem;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 2px -2px rgba(0, 0, 0, 0.06);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm, .btn-group-sm > .btn {
  height: 2.075rem;
  padding: 0.425rem 0.575rem;
  font-weight: 500;
  font-size: 0.7625rem;
  line-height: 1.5;
}

.btn-block {
  display: block;
  width: 100%;

  + .btn-block {
    margin-top: 0.5rem;
  }
}

[class*=btn-outline-] {
  border-width: 1px;
  background-color: transparent;

  &:hover, &:focus {
    color: $white;
  }
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  background-color: inherit;
  border-color: inherit;

  &:hover, &:focus, &:active {
    background-color: inherit;
    border-color: inherit;
  }
}

.btn.disabled {
  opacity: 0.65;
}

.btn-disabled {
  color: $white;
  background-color: $gray-300;
  border-color: $gray-300;

  &:hover, &:focus {
    color: $white;
    background-color: $gray-300;
    border-color: $gray-300;
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $gray-300;
    border-color: $gray-300;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $gray-300;
      border-color: $gray-300;
    }
  }
}

.btn-send{
  position: absolute;
  bottom: 20px;
  right: 0;
  height: 1rem;
  font-weight: 400 !important;
}

.btn-flat-gray {
  color: $gray-400 !important;
  font-weight: 600;
  border: none !important;
  box-shadow: none !important;


  &:hover {
    color: $gray-500 !important;
    border: none !important;
  }

  &:focus,
  &.focus {
    text-shadow: 0 0 0 2px lighten($primary-700, 40);
  }

  &.disabled,
  &:disabled {
    color: $gray-500 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {

    &:active,
    &.active {
      color: $primary-500 !important;
      font-weight: 600;
      border: none !important;
    }
  }
}

.btn-transparent {
  color: $gray-900;
  background-color: transparent;
  border-color: $gray-200;

  &:hover, &:focus, &.focus {
    background-color: $gray-50;
    border-color: $gray-300;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($gray-200, 7.5);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      background-color: $gray-100;
      border-color: $gray-300;
    }
  }
}

.btn-primary {
  color: $white;
  background-color: $primary-500;
  border-color: $primary-500;

  &:hover, &:focus, &.focus {
    color: $white;
    background-color: lighten($primary-500, 7.5);
    border-color: lighten($primary-500, 7.5);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($primary-500, 7.5);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: lighten($primary-500, 7.5);
      border-color: $primary-500;
    }
  }
}

.btn-outline-primary {
  color: $primary-400 !important;
  border-color: $primary-400;

  &:hover {
    background-color: $primary-50;
    border-color: $primary-700;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($primary-700, 45%);
  }

  &.disabled, &:disabled {
    color: $primary-700 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      background-color: $primary-50;
      border-color: $primary-700;
    }
  }
}

.btn-flat-primary {
  color: $primary-500 !important;
  font-weight: 600;
  border: none !important;
  box-shadow: none !important;


  &:hover {
    color: $primary-500 !important;
    font-weight: 600;
    border: none !important;
  }

  &:focus, &.focus {
    text-shadow: 0 0 0 2px lighten($primary-700, 40);
  }

  &.disabled, &:disabled {
    color: $gray-500 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $primary-500 !important;
      font-weight: 600;
      border: none !important;
    }
  }
}


.btn-outline-primary-600 {
  color: $primary-600 !important;
  border-color: $primary-600;

  &:hover {
    background-color: lighten($primary-50, 25%);
    border-color: $primary-600;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($primary-600, 45%);
  }

  &.disabled, &:disabled {
    color: $primary-600 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      background-color: lighten($primary-600, 40);
      border-color: $primary-600;
    }
  }
}




.btn-secondary {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;

  &:hover, &:focus, &.focus {
    color: $white;
    background-color: lighten($secondary, 10);
    border-color: lighten($secondary, 10);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($secondary, 40);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: lighten($secondary, 20);
      border-color: $secondary;
    }
  }
}

.btn-outline-secondary {
  color: $secondary !important;
  border-color: $secondary;

  &:hover {
    color: $white !important;
    background-color: $secondary;
    border-color: $secondary;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($secondary, 40);
  }

  &.disabled, &:disabled {
    color: $secondary !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }
}

.btn-info {
  color: $white;
  background-color: $info;
  border-color: $info;

  &:hover, &:focus, &.focus {
    color: $white;
    background-color: lighten($info, 10);
    border-color: lighten($info, 10);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px darken($info, 40);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: lighten($info, 20);
      border-color: $info;
    }
  }
}

.btn-outline-info {
  color: $info !important;
  border-color: $info;

  &:hover {
    color: $white !important;
    background-color: $info;
    border-color: $info;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($info, 45%);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }
}

.btn-danger-400 {
  color: $white;
  background-color: $danger-400;
  border-color: $danger-400;

  &:hover, &:focus, &.focus {
    color: $white;
    background-color: lighten($danger-400, 10);
    border-color: lighten($danger-400, 10);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px darken($danger-400, 40);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: lighten($danger-400, 20);
      border-color: $danger-400;
    }
  }
}

.btn-outline-danger-400 {
  color: $danger-400 !important;
  border-color: $danger-400;

  &:hover {
    background-color: lighten($danger-400, 50%);
    border-color: $danger-400;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($danger-400, 40);
  }

  &.disabled, &:disabled {
    @extend .btn-disabled;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: $danger-400;
      border-color: $danger-400;
    }
  }
}
.btn-flat-danger-400 {
  color: $danger-400 !important;
  font-weight: 600;
  border: none !important;
  box-shadow: none !important;


  &:hover {
    color: $danger-400 !important;
    font-weight: 600;
    border: none !important;
  }

  &:focus, &.focus {
    text-shadow: 0 0 0 2px lighten($danger-400, 40);
  }

  &.disabled, &:disabled {
    color: $gray-500 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $danger-400 !important;
      font-weight: 600;
      border: none !important;
    }
  }
}

@media (max-width: 576px) {
  .btn {
    padding: 0.375rem 0.75rem;
  }

  .btn-sm, .btn-group-sm > .btn {
    padding: 0.375rem;
  }
}


