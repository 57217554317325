@import "../variables";

.alert {
  display: flex;
  padding: 0;
  background: $white;
  border-color: transparent;
  border-radius: 0.25rem;
  margin-bottom: 35px;

  &-primary {
    border-left: 6px solid $primary-500;
  }

  &-icon-container {
    display: flex;
    padding: 1.125rem 0.625rem;
    width: 3.5rem;
    justify-content: center;
    align-items: center;

    &-primary {
      background-color: $primary-50;
    }
  }

  &-content {
    display: flex;
    column-gap: 10px;
    width: calc(100vw - 5rem);
    column-gap: 10px;
    padding: 15px 20px 10px;
    justify-content: space-between;
  }

}

.box {
  display: inline-block;
  padding: 10px 0;
  box-sizing: border-box;
}

.box-shadow-soft {
  box-shadow: 0 10px 18px -2px rgba(16, 25, 40, 0.07);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0;
  color: $gray-400;
}

.breadcrumb-item {
    @extend .cursor-pointer;
  +.breadcrumb-item {
    margin: 0;

    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #a8afc7;
      content: "/";
    }

    &:hover::before {
      text-decoration: none;
    }
  }

  &.active {
    color: $primary-500;
  }
}

.comment {
  padding: 1rem 0.85rem;
  border-radius: 0 0.5rem 0.5rem 0.5rem;

  &.comment-even {
    background-color: $gray-75;
    color: $gray-900;
  }

  &.comment-odd {
    background-color: $secondary-400;
    color: $white;
  }
}

.css-1jqq78o-placeholder,
.css-166bipr-Input,
.css-qbdosj-Input {
  padding: 0 0.525rem;
}

.css-tj5bde-Svg {
  color: $gray-900;
  font-weight: lighter;
}

.css-20t4a9-control[aria-disabled="true"] {
  .css-tj5bde-Svg {
    color: $gray-300;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.img-preview {
  overflow: hidden;
  display: block;
  margin: 0 auto;
}

.invoice {
  display: flex;
  background-color: $white;
  width: 800px;
  height: 825px;
  padding: 40px;
  max-width: 100%;
  max-height: 100%;
}

.no-data-card {
  background-color: $white;
  min-height: 50vh;
  border-radius: 0 0 0.625rem 0.625rem;
  box-shadow: 0 10px 18px -2px rgba(16, 25, 40, 0.07);
}

.password-validator-wrapper {
  display: flex;
  margin-bottom: 0.25rem;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;

  &img {
    width: 1.25rem;
    height: 1.25rem;
  }

  &p {
    color: $gray-300;
  }
}

.dash-timeline ul.task-list {
  list-style-type: none;
  position: relative;
  margin: 30px 0;

  &::before {
    content: '';
    position: absolute;
    margin: 0 20px;
    display: inline-block;
    left: -3px;
    height: 90%;
    z-index: 0;
    bottom: 5px;
    border-left: 2px dashed $gray-700;
  }

  &>li {
    margin-bottom: 40px;
    padding-left: 20px;
    position: relative;

    &:last-child {
      margin-bottom: 15px;
    }

    >.task-icon {
      content: "";
      position: absolute;
      left: -35px;
      top: -3px;
      width: 40px;
      height: 40px;
      z-index: 2;
      padding: 11px;
      border-radius: 50%;
      background: $primary-50;
    }
  }
}

.sketch-picker {
  width: 230px !important;
  padding: 16px 16px 0px !important;
  background: $white !important;
  border-radius: 8px !important;
  box-shadow: 0px 10px 15px 0px #1F29371A !important;

  .flexbox-fix {
    display: flex !important;
    justify-content: center !important;
    gap: 2px !important;

    &:last-child div {
      width: 22px !important;
      height: 22px !important;
      border-radius: 50% !important;
    }
  }
}

.template-parent {
  height: 520px;
  border: 1.39px solid $gray-200;
  max-width: 455px;
  margin-bottom: 45px;


  >.template-child {
    border: 1.39px solid $gray-200;
    background-color: $white;
    height: 520px;
    position: relative;
    margin: 0 auto;
    width: 85%;
    padding: 0 12px;
    transform: scale(1.085);
    -webkit-transform: scale(1.085);

    small {
      font-size: 9px;
      line-height: 6px !important;
    }

    p {
      font-size: 11px;
    }

    table {

      thead tr,
      tbody tr {
        height: 20px;
        // border-bottom: 1px solid $gray-400;
      }
    }
  }
}

@media (max-width: 350px) {
  .sketch-picker {
    width: auto !important;
  }
}