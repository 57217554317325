@import "../variables";

.mobile-main-header {
  position: fixed !important;
  top: 60px;
}

.main-header, .mobile-main-header {
  height: 60px;
  background-color: $white;
  z-index: 5;
  width: 100%;

  > {
    .container, .container-fluid {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.main-header-left {
  display: flex;
  align-items: center;
}


.main-header-menu-icon {
  width: 20px;
  display: flex;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}


.main-header-center {
  margin-left: $sidebar-width;

  .header-logo {
    max-width: 110px;
  }

  .input-group {
    margin-top: 0;

    .input-group-text-left {
      left: 7px;
      top: 7px;
      padding-right: 0;
    }

    .form-control {
      transition: none;
      width: 39.375rem;
      flex: inherit;
      height: 2.1rem !important;
      padding: 0.5rem 0.75rem 0.5rem 2.5rem;
      border-radius: 0.375rem !important;
      border: 1px solid $gray-300;
      background: $white;


      &:focus {
        outline: 0;
        border-color: $primary-200;
        box-shadow: none;
      }
    }
  }
}

.main-header-right {
  display: flex;
  align-items: center;
}

.main-header-notification {
  position: relative;
}

.navbar-toggler .header-icons {
  padding: 0;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 20px;
  border-radius: 50%;
  line-height: 35px;
}

.navbar-toggler .header-icons:hover {
  background-color: $gray-100;
  border-radius: 50%;
}

@media (max-width: 991px) {
  .main-header {
    z-index: 10 !important;

    > {
      .container, .container-fluid {
        padding-left: 25px;
        padding-right: 15px;
      }
    }
  }
  .main-header-center {
    margin-left: 0;
  }
}

@media (max-width: 1095px) {
  .main-header-center {
    .input-group {
      .form-control {
        width: 32.5rem;
      }
    }
  }
}


@media (max-width: 620px) {
  .main-header-center {
    .input-group {
      .form-control {
        width: 28rem;
      }
    }
  }
}

@media (max-width: 540px) {
  .main-header, .mobile-main-header {
    > {
      .container, .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .main-header-center {
    .input-group {
      .form-control {
        width: 25rem;
      }
    }
  }

}

@media (max-width: 485px) {
  .main-header-center {
    .input-group {
      .form-control {
        width: 20rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .main-header-center {
    .input-group {
      .form-control {
        width: 13rem;
      }
    }
  }
}

