@import "../variables";

.main-sidebar {
  &.side-menu {
    position: fixed;
    padding: 0.75rem 0;
    max-height: 100vh;
    z-index: 6;
    overflow-x: hidden;
  }

  &.main-sidebar-sticky {
    background: $white;
    box-shadow: none;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: column;
    &.collapsed-width{
      overflow: visible;
    }
  }
}

.expanded-width {
  width: $sidebar-width;
}

.expanded-width-2 {
  width: 100%;
}

.collapsed-width {
  width: $sidebar-width-collapsed;
}

.main-sidebar-header {
  margin: 5px auto 20px;
  padding: 0 0.5rem;
  .main-profile-menu {
    h6 {
      &::after {
        content: " ";
        height: 10px;
        width: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background-color: $success-600;
      }
    }
  }
}

.main-sidebar-body {
  display: flex;
  max-height: 83%;
  border-bottom: 1px solid $gray-100;
  padding: 0 0.5rem;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: content-box;
  scrollbar-width: thin;

  &.expanded-width-2 {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .nav {
    min-width: calc(100% - 1.25em);
    flex-direction: column;
    padding: 7px 0;

    .nav-submenu {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
    }

    &-link {
      color: $gray-600;
      font-size: 14px;
    }

    &-submenu-link {
      color: $gray-500;
      font-size: 14px;
      padding-left: 2.85rem !important;
    }

    &-link, &-submenu-link {
      display: flex;
      justify-content: space-between;
      line-height: 1.25rem;
      margin-right: 10px;
      padding: 0.875rem 0.75rem;

      &.show, &:hover {
        color: $gray-900;
      }

      &-title {
        margin-left: 0.75rem;
      }

      img {
        margin-right: 0;
        margin-top: -0.1rem;
        width: 20px;
        height: 20px;
      }

      &.show + .nav-submenu.list-unstyled.show {
        max-height: 100vh;
        transition: all 2s;
      }

      &.show + .nav-submenu.list-unstyled.hide {
        max-height: 0;
        transition: all .5s;
      }
    }

    &-link.active-parent-menu {
      color: $gray-900;
    }

    li.nav-item {
      cursor: pointer;
      position: relative;
      margin-bottom: 8px;


      &.active {
        border-radius: 5px !important;



        .nav-submenu-link {
          background-color: $primary-50;
        }

        .nav-link, .nav-submenu-link {
          color: $gray-900;
          font-weight: 500;
          position: relative;
          width: inherit;
          height: inherit;
        }
      }
    }
  }
}

.tooltip__content {
  position: absolute !important;
  left: 3.75em;
  top: 12px;
  transform: translateY(-50%);
  background-color: $primary-50;
  color: $gray-900;
  padding: 0.5em;
  border-radius: 5px;
  white-space: nowrap;
  width: fit-content;
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  //display: none;
  transition: opacity 0.5s ease, visibility 0.5s ease, background-color 3s ease, color 1.5s ease;
  z-index: 99999999999999999;

  &:before {
    content: '';
    position: absolute;
    top: 85%; /* Align the arrow with the center of the tooltip */
    left: -10px; /* Adjust the arrow's position to align with the tooltip */
    transform: translateY(-85%); /* Center the arrow vertically */
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 10px 8px 0; /* Arrow shape */
    border-color: transparent $primary-50 transparent transparent;
  }
}

.nav-item:hover .tooltip__content {
  //display: block;
  visibility: visible;
  opacity: 1;
}

.main-sidebar-footer {
  background-color: $white;
  position: absolute;
  bottom: 0.75rem !important;
  left: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 20px;
  max-height: calc(100% - 81.5%);
}

.footer-padding {
  padding: 15px 15px 10px;
}

@media (max-width: 991px) {
  .main-sidebar {
    &.side-menu {
      margin-top: calc($header-height - 15px);
      z-index: 999 !important;
    }
  }
  .main-sidebar-header{
    display: none;
    visibility: hidden;
  }
}

@media (max-height: 500px) {
  .main-sidebar-body {
    max-height: 72%;
  }
}

@media (max-width: 576px) {
  .main-sidebar-body {
    padding: 0.5px 0.525rem;
  }

  .nav {
    &-link, &-submenu-link {
      font-size: 13px;
      padding: 0.725rem 0.425rem !important;
    }
  }
  .main-sidebar-footer {
    border-top: 1px solid $gray-100;
  }
  .footer-padding {
    padding: 8px  10px;
  }
}
