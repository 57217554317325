@import "../variables";

.table-responsive,
.custom-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  >.table-bordered {
    border: 0;
  }
}


.table-responsive {
  background: $white;
  padding-bottom: 80px;
  border-radius: 0.625rem 0.625rem 0 0;
  box-shadow: 0 10px 18px -2px rgba(16, 25, 40, 0.07);
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: none;
  border-width: 0;
}


.custom-table,
.table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  position: relative;


  th,
  td {
    padding: 1.5rem;
    vertical-align: middle;
    height: 2.8125rem;
    line-height: 1.462;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 0 !important;
    height: 2.8125rem;
  }

  thead th:first-child {
    border-radius: 0.625rem 0 0 0;
  }

  thead th:last-child {
    border-radius: 0 0.625rem 0 0;
  }

  thead {
    border-bottom-width: 0;
    border-top-width: 0;

    th,
    td {
      color: $gray-700;
      font-weight: bold;
      font-size: 0.75rem;
      letter-spacing: -0.00375rem;
      text-transform: capitalize;
      padding: 0.75rem 1.5rem;
      height: 2.75rem;
      font-style: normal;
      line-height: 1.0875rem;
    }
  }

  tbody tr {
    td {
      color: $gray-700;
      @extend .text-truncate;
    }
  }

}

.custom-table {
  thead {

    th,
    td {
      color: $primary !important;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.table {
  tbody+tbody {
    border-top: 1px solid $gray-75;
  }

  thead {

    th,
    td {
      background-color: $gray-100;
    }
  }

  tbody tr {
    height: 4.5625rem;
    border-top: 1px solid $neutral-200;

    td {
      color: $gray-700;
      @extend .text-truncate;
    }
  }
}

.table-footer {
  margin: 0;
  background-color: $white;
  padding: 1rem 0 1rem 0.25rem;
  border-radius: 0 0 0.625rem 0.625rem;
  position: relative;
  border-top: 1px solid $gray-200;
}